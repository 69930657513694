/**
 * Clarus API client.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Client {
	/**
	 * Create a new Client instance.
	 *
	 * @param {Manager} manager
	 */
	constructor(manager) {
		/**
		 * Service manager driver instance.
		 *
		 * @type {Manager}
		 */
		this.manager = manager
	}

	/**
	 * Make a new Auth instance.
	 *
	 * @return {Auth}
	 */
	auth() {
		return this.manager.service('auth')
	}

	/**
	 * Make a new calendars instance.
	 *
	 * @return {calendars}
	 */
	calendars() {
		return this.manager.service('calendars')
	}

	/**
	 * Make a new Calls instance.
	 *
	 * @return {Calls}
	 */
	calls() {
		return this.manager.service('calls')
	}

	/**
	 * Make a new CallTypes instance.
	 *
	 * @return {CallTypes}
	 */
	callTypes() {
		return this.manager.service('callTypes')
	}

	/**
	 * Make a new Events instance.
	 *
	 * @return {Events}
	 */
	events() {
		return this.manager.service('events')
	}

	/**
	 * Make a new eventExceptions instance.
	 *
	 * @return {Events}
	 */
	eventExceptions() {
		return this.manager.service('eventExceptions')
	}

	/**
	 * Make a new EventTypes instance.
	 *
	 * @return {EventTypes}
	 */
	eventTypes() {
		return this.manager.service('eventTypes')
	}

	/**
	 * Make a new MessagingProfiles service instance.
	 *
	 * @return {MessagingProfiles}
	 */
	messagingProfiles() {
		return this.manager.service('messagingProfiles')
	}

	/**
	 * Make a new Notes service instance.
	 *
	 * @return {Notes}
	 */
	notes() {
		return this.manager.service('notes')
	}

	/**
	 * Make a new Password instance.
	 *
	 * @return {Password}
	 */
	password() {
		return this.manager.service('password')
	}

	/**
	 * Make a new Partners instance.
	 *
	 * @param {Number?} id
	 * @return {Partners}
	 */
	partners(id) {
		return this.manager.service('partners', [].concat(id || []))
	}

	/**
	 * Make a new Providers instance.
	 *
	 * @return {Providers}
	 */
	providers() {
		return this.manager.service('providers')
	}

	/**
	 * Make a new TimeBlocks instance.
	 *
	 * @return {TimeBlocks}
	 */
	timeBlocks() {
		return this.manager.service('timeBlocks')
	}

	/**
	 * Make a new Users instance.
	 *
	 * @return {Users}
	 */
	users() {
		return this.manager.service('users')
	}

	eventLogger() {
		return this.manager.service('eventLogger')
	}
}
