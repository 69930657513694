import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'
import Application from 'App/Foundation/Application'
import {
	SET_ACTIVE,
	SET_PENDING_CALLS_COUNT,
	SET_PENDING_CALLS_COUNT_BY_ID,
} from '@/store/mutation-types'

/**
 * The module's dispatchable actions.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Decrement the partner's pending calls count.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	decrementPendingCallsCount({ commit, state }) {
		const partner = state.items[state.active]

		const current = partner?.pending_calls_count || 0

		commit(SET_PENDING_CALLS_COUNT, current - 1)
	},

	/**
	 * Find a partner by id.
	 *
	 * @param {Function} options.dispatch
	 * @param {Number} id
	 * @return {void}
	 */
	async find({ dispatch }, id) {
		const response = await api()
			.partners()
			.find(id)

		let { providers = [], calendars = [], ...partner } = response.get(
			'data',
			{}
		)

		calendars = calendars.map(calendar => ({ ...calendar, events: [] }))

		await dispatch('calendars/add', calendars, { root: true })
		await dispatch('providers/set', providers, { root: true })

		await dispatch('add', partner)

		return response
	},

	/**
	 * Get all the partners.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @return {Promise}
	 */
	async get({ dispatch, state }) {
		const response = await api()
			.partners()
			.get()

		const data = response.get('data', [])

		const defaultPartnerId = data.length ? data[0].id : null

		const activePartnerId = state.active

		await dispatch('add', data)

		if (!activePartnerId) {
			await dispatch('setActive', defaultPartnerId)
		}

		if (activePartnerId) {
			await dispatch('find', activePartnerId)
		}

		return response
	},

	/**
	 * Increment the partner's pending calls count.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	incrementPendingCallsCount({ commit, state }) {
		const partner = state.items[state.active]

		const current = partner?.pending_calls_count || 0

		commit(SET_PENDING_CALLS_COUNT, current + 1)
	},

	incrementPendingCallsCountById({ commit }, callCount) {
		commit(SET_PENDING_CALLS_COUNT_BY_ID, callCount)
	},

	/**
	 * Set the active partener.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Number} id
	 * @return {void}
	 */
	async setActive({ commit, dispatch, state }, id) {
		if (state.active && state.active !== id) {
			Application.getInstance().emit('partner:change', {
				leave: state.active,
				subscribe: id,
			})
		}

		if (state.active !== id) {
			commit(SET_ACTIVE, id)
		}

		if (id) {
			await dispatch('find', id)
		}
	},

	/**
	 * Update the partner.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} partner
	 * @return {Promise}
	 */
	async update({ dispatch, state }, partner) {
		const response = await api()
			.partners()
			.update(partner)
		const payload = response.get('partner')
		const emrConfig = response.get('emr_config')
		const activePartnerId = state.active
		const existingEmrConfig = state.items[activePartnerId].emr_settings

		if (response.get('emr_config')) {
			payload.emr_settings = {
				...existingEmrConfig,
				...emrConfig,
				note_types: JSON.stringify(emrConfig.note_types),
				partner_id: response.get('partner').id,
			}
		}
		dispatch('add', payload)

		return response
	},
}
