import Notes from './Notes'
import Service from './Service'
import {
	CALLS_FILTER,
	FULL_VISIBILITY_FILTER,
	PAGING_FILTER,
	statuses,
} from '@/config/calls'

/**
 * Calls API service.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Calls extends Service {
	/**
	 * Create a new Service instance.
	 *
	 * @param {Request} request
	 * @param {Number?} partner
	 */
	constructor(request, partner) {
		super(request)

		/**
		 * The active partner id to scope the calls requests by.
		 *
		 * @type {Number?}
		 */
		this.partner = partner
	}

	/**
	 * Add the calls only query scope filter.
	 *
	 * @return {Calls}
	 */
	callsOnly() {
		return this.pushQueryParam('filters', CALLS_FILTER)
	}

	/**
	 * Attempt to complete a call.
	 *
	 * @param {String} sid
	 * @param {String} message
	 * @return {Promise}
	 */
	complete(sid, message) {
		return this.request.post(`v3/calls/${sid}/complete`, {
			completion_message: message,
		})
	}

	/**
	 * Attempt to dismiss a call.
	 *
	 * @param {String} sid
	 * @param {String} message
	 * @return {Promise}
	 */
	dismiss(sid, message) {
		return this.request.post(`v3/calls/${sid}/dismiss`, {
			completion_message: message,
		})
	}

	/**
	 * Create a new call (page).
	 *
	 * @param {Number} payload.provider_id
	 * @param {String} payload.callback_number
	 * @param {String} payload.patient_name
	 * @param {String} payload.patient_message
	 * @param {String} payload.patient_dob
	 * @param {Number} payload.partner_id
	 * @return {Promise}
	 */
	create(payload) {
		return this.request.post('v3/calls', payload)
	}

	/**
	 * Set the ends time query scope parameter.
	 *
	 * @param {String} ends
	 * @return {Calls}
	 */
	ends(ends = null) {
		return this.setQueryParam('ends', ends)
	}

	/**
	 * Find a model by the given SID.
	 *
	 * @param {String} sid
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	find(sid, partner = null) {
		partner = partner || this.partner

		return this.request.get(`v4/partners/${partner}/calls/${sid}`)
	}

	/**
	 * Get all calls from the API.
	 *
	 * @param {Boolean} fullVisibility
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	get(fullVisibility = true, partner = null) {
		partner = partner || this.partner

		if (fullVisibility) {
			this.withFullVisibility()
		}

		this.withAllStatuses()

		return this.request.get(
			`v4/partners/${partner}/calls`,
			this.getOptions()
		)
	}

	/**
	 * Add a filter to the filters query scope parameter.
	 *
	 * @param {Array|Number} filters
	 * @return {Calls}
	 */
	filters(filters) {
		return this.pushQueryParam('filters', filters)
	}

	/**
	 * Get the number of incomplete calls w/ the specified filters.
	 *
	 * @param {Boolean} fullVisibility
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	count(fullVisibility = true, partner = null) {
		partner = partner || this.partner

		if (fullVisibility) {
			this.withFullVisibility()
		}

		this.withAllStatuses()

		return this.request.get(
			`v4/partners/${partner}/calls/count`,
			this.getOptions()
		)
	}

	/**
	 * Set the non-urgent query scope parameter.
	 *
	 * @param {Boolean} nonUrgent
	 * @return {Calls}
	 */
	nonUrgent(nonUrgent = true) {
		return this.setQueryParam('nonUrgent', nonUrgent)
	}

	/**
	 * Make a new Notes resource instance.
	 *
	 * @return {Notes}
	 */
	notes() {
		return new Notes(this.request)
	}

	/**
	 * Set the current page to request data from.
	 *
	 * @param {Number} page
	 * @return {Calls}
	 */
	page(page = 1) {
		return this.setQueryParam('page', page || 1)
	}

	/**
	 * Add the paging only query scope filter.
	 *
	 * @return {Calls}
	 */
	pagingOnly() {
		return this.pushQueryParam('filters', PAGING_FILTER)
	}

	/**
	 * Add the partner ids to the partners query scope parameter.
	 *
	 * @param {Array|Number} ids
	 * @return {Calls}
	 */
	partners(ids) {
		if (!ids) {
			throw new Error(
				`Invalid id(s) param supplied to Calls.partners(ids: Array|Integer) [received: ${ids}].`
			)
		}

		return this.pushQueryParam('partners', ids)
	}

	/**
	 * Send a patch request to update a specific field's value.
	 *
	 * @param {String} sid
	 * @param {String} field
	 * @param {String} value
	 * @return {Promise}
	 */
	patch(sid, field, value) {
		return this.request.patch(`v3/calls/${sid}`, { field, value })
	}

	/**
	 * Add a filter to the providers query scope parameter.
	 *
	 * @param {Array|Number} providers
	 * @return {Calls}
	 */
	providers(providers) {
		return this.pushQueryParam('providers', providers)
	}

	/**
	 * Reassign a call to the given provider.
	 *
	 * @return {Promise}
	 */
	reassign({ sid, providerId, urgentForward }) {
		return this.request.put(`v3/calls/${sid}/reassign`, {
			provider_id: providerId,
			urgent_forward: urgentForward,
		})
	}

	/**
	 * Set the search term query scope parameter.
	 *
	 * @param {String} ter
	 */
	search(term) {
		return this.setQueryParam('search', term)
	}

	/**
	 * Set the start time query scope parameter.
	 *
	 * @param {String} starts
	 * @return {Calls}
	 */
	starts(starts = null) {
		return this.setQueryParam('starts', starts)
	}

	/**
	 * Add statuses to the statuses query scope parameter.
	 *
	 * @param {Array|String} statuses
	 * @return {Calls}
	 */
	statuses(statuses) {
		return this.pushQueryParam('statuses', statuses)
	}

	/**
	 * Add the call types to the query scope parameters.
	 *
	 * @param {Array|Number} types
	 * @return {Calls}
	 */
	types(types) {
		return this.pushQueryParam('types', types)
	}

	/**
	 * Update the given call's information.
	 *
	 * @param {Number} sid
	 * @param {Array} types
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	update(sid, types, partner = null) {
		partner = partner || this.partner

		return this.request.put(`v4/partners/${partner}/calls/${sid}`, {
			types,
		})
	}

	/**
	 * Update the EMR call id information.
	 *
	 * @param {Number} sid
	 * @param {Array} types
	 * @param {Number?} partner
	 * @return {Promise}
	 */
	updateEMR(sid, callPatientDetails, partner = null) {
		partner = partner || this.partner

		return this.request
			.put(`v4/partners/${partner}/calls/${sid}`, {
				call_patient_details: callPatientDetails,
			})
			.then(response => {
				return response
			})
			.catch(error => {
				throw error
			})
	}

	/**
	 * Set the urgent query scope parameter.
	 *
	 * @param {Boolean} urgent
	 * @return {Calls}
	 */
	urgent(urgent = true) {
		return this.setQueryParam('urgent', urgent)
	}

	/*
	 * Add all statuses to the statuses query scope parameter.
	 *
	 * @return {CallTypes}
	 */
	withAllStatuses() {
		return this.pushQueryParam(
			'statuses',
			statuses.map(status => status.name)
		)
	}

	/**
	 * Add the full visibility query scope filter.
	 *
	 * @return {Calls}
	 */
	withFullVisibility() {
		return this.pushQueryParam('filters', FULL_VISIBILITY_FILTER)
	}

	/**
	 * Remove the the full visibility query scope filter.
	 *
	 * @return {Calls}
	 */
	withoutFullVisibility() {
		return this.removeFromQueryParamArray('filters', FULL_VISIBILITY_FILTER)
	}
}
