import moment from 'moment'

/**
 * Global Vue.js filters.
 *
 * @see https://vuejs.org/v2/guide/filters.html
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default {
	/**
	 * Get initials from the given full name.
	 *
	 * @param {String} name
	 * @return {String}
	 */
	getInitials(name) {
		if (!name) {
			return '#'
		}

		return name
			.split(' ')
			.map(word => word.charAt(0))
			.join('')
			.toUpperCase()
	},

	/**
	 * Format a U.S. phone number into a readable format.
	 *
	 * @param {String} phone
	 * @return {String}
	 */
	formatPhoneNumber(phone) {
		const cleaned = ('' + phone).replace(/\D/g, '')

		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

		if (match) {
			return `(${match[2]}) ${match[3]}-${match[4]}`
		}

		return phone
	},

	/**
	 * Format a U.S. phone number into a readable format, this will format as per the new requirement xxx-xxx-xxxx
	 *
	 * @param {String} phone
	 * @return {String}
	 */
	newFormatPhoneNumber(phone) {
		const cleaned = ('' + phone).replace(/\D/g, '')

		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

		if (match) {
			return `${match[2]}-${match[3]}-${match[4]}`
		}

		return phone
	},

	/**
	 * Cast the given timestamp to a formatted date string.
	 *
	 * @param {String} timestamp The timestamp string to be formatted.
	 * @param {?String|Array} format The given timestamp's format.
	 * @return {String}
	 */
	toDate(timestamp, format = ['YYYY-MM-DD HH:mm:ss']) {
		return moment(timestamp, format).format('MM/DD/YYYY')
	},

	/**
	 * Cast the given timestamp to a formatted datetime string.
	 *
	 * @param {String} timestamp The timestamp string to be formatted.
	 * @param {?String|Array} format The given timetstamp's format.
	 * @return {String}
	 */
	toDateTime(timestamp, format = ['YYYY-MM-DD HH:mm:ss']) {
		return moment
			.utc(timestamp, format)
			.local()
			.format('MM/DD/YYYY hh:mm A')
	},

	/**
	 * Cast the given timestamp to a formatted datetime string.
	 *
	 * @param {String} timestamp
	 * @param {?String|Array} format
	 * @return {String}
	 */
	toDateTimeFormat(timestamp, format = 'MM/DD/YYYY hh:mm A') {
		return moment
			.utc(timestamp, ['YYYY-MM-DD HH:mm:ss'])
			.local()
			.format(format)
	},

	/**
	 * Displayable filter name.
	 *
	 * @return {String}
	 */
	toFilterName(name) {
		switch (name) {
			case 'complete':
				return 'Complete'
			case 'fallback':
				return 'Fallback'
			case 'new':
			case 'incomplete':
				return 'New'
			case 'noted':
				return 'Notes'
			case 'urgent':
				return 'Urgent'
			case 'rsm_message':
				return 'Voicemail'
		}
	},

	/**
	 * Convert a string to all lowercase characters.
	 *
	 * @param {String} text
	 * @return {String}
	 */
	toLowerCase(text) {
		return text.toLowerCase()
	},

	/**
	 * Get the given provider's last name and first initial.
	 *
	 * @param {Object} provider
	 * @return {String}
	 */
	toProviderShortName(provider) {
		let displayName = ''

		if (!provider.full_name) {
			return 'Provider Removed'
		}

		if (provider.full_name) {
			let [first_name, ...last_name] = provider.full_name.split(' ')
			const firstInitial = first_name.charAt(
				first_name.search(/[a-zA-Z]/) || 0
			)

			last_name = last_name.join(' ')

			displayName = `${last_name}, ${firstInitial}`
		}

		if (provider.type == 'external') {
			return `(Ext) ${displayName}`
		}

		return displayName
	},

	/**
	 * Cast the given datetime to a formatted timestamp string.
	 *
	 * @param {String} timestamp The timestamp string to be formatted.
	 * @param {String} outputFormat The format moment should output the timestamp in.
	 * @param {?String|Array} format The given timestamp's format.
	 * @return {String}
	 */
	toTimestamp(
		timestamp,
		outputFormat = 'hh:mm A',
		format = 'YYYY-MM-DD HH:mm:ss'
	) {
		if (moment.isMoment(timestamp)) {
			return moment.local().format(outputFormat)
		}

		return moment
			.utc(timestamp, format)
			.local()
			.format(outputFormat)
	},

	/**
	 * Capitalize the first letter of the given word.
	 *
	 * @return {String}
	 */
	ucFirst(word) {
		return word.charAt(0).toUpperCase() + word.slice(1)
	},
}
