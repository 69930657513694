import Service from './Service'

/**
 * EventLogger API service.
 *
 */
export default class EventLogger extends Service {
	create(payload) {
		return this.request.post('v4/events', payload)
	}
}
