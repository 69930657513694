/**
 * Determine if the given item is an array.
 *
 * @param {*} item
 * @return {Boolean}
 */
export const isArray = item => Array.isArray(item)

/**
 * Determine if the given item is an object.
 *
 * @param {*} item
 * @return {Boolean}
 */
export const isObject = item => typeof item === 'object' && item !== null

/**
 * Save the given key/value pair to local storage.
 *
 * @param {String} key
 * @param {Object} data
 * @return {Object}
 */
export const saveToStorage = (key, data) => {
	let value = data

	if (data && typeof data === 'object') {
		const cached_at = new Date().toISOString()

		value = { cached_at, ...data }
	}

	localStorage.setItem(key, JSON.stringify(value))

	return data
}

/**
 * Cast the given item to an array.
 *
 * @param {*} item
 * @return {Array}
 */
export const toArray = item => {
	if (isArray(item)) {
		return item
	}

	if (item) {
		return [].concat(item)
	}

	return []
}

export const setCookie = (name, value, days) => {
	let expires = ''
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = '; expires=' + date.toUTCString()
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = name => {
	const nameEQ = name + '='
	const ca = document.cookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) == ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
	}
	return null
}

export const eraseCookie = name => {
	document.cookie = name + '=; Max-Age=-99999999;'
}
