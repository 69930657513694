import stateFactory from './state'
import * as helpers from '@/store/helpers'
import {
	ADD_ITEMS,
	ADD_NOTE_EVENT,
	ADD_NOTE_TO_PAGE,
	COMPLETE_CALL,
	REMOVE_BY_ID,
	REMOVE_STATUS_FILTERS,
	RESET,
	RESET_FILTERS,
	REMOVE_NOTE_FROM_PAGE,
	SET_CALL_PROVIDER_FILTERS,
	SET_INCOMPLETE_PAGING_COUNT,
	SET_ITEMS,
	SET_FILTER_IDS,
	SET_FILTER_META,
	SET_META,
	SET_SEARCH_FILTER,
	SET_STATUS_FILTERS,
	UPDATE_PAGE_NOTE,
	REMOVE_SEARCH_FILTER,
	SET_LOADING,
	REMOVE_DATE_RANGE_FILTER,
	SET_STARTS_TIME_FILTER,
	SET_CALL_TYPE_FILTERS,
	SET_ENDS_TIME_FILTER,
} from '@/store/mutation-types'

/**
 * The module's state mutators.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Set the call's start time filter.
	 *
	 * @param {Object} state
	 * @param {String} starts
	 * @return {void}
	 */
	[SET_STARTS_TIME_FILTER](state, starts) {
		state.filters.starts = starts
	},

	[SET_CALL_TYPE_FILTERS](state, types) {
		state.filters.types = [...[].concat(types)]
	},

	/**
	 * Set the call's ends time filter.
	 *
	 * @param {Object} state
	 * @param {String} ends
	 * @return {void}
	 */
	[SET_ENDS_TIME_FILTER](state, ends) {
		state.filters.ends = ends
	},

	/**
	 * Add the given model(s) to the store.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 */
	[ADD_ITEMS](state, models) {
		helpers.add(state, models)
	},

	/**
	 * Add the page note created event.
	 *
	 * @param {Object} state
	 * @param {Object} event
	 * @return {void}
	 */
	[ADD_NOTE_EVENT](state, event) {
		const page = state.items[event.call_id]
		const events = (page.events || []).concat(event)

		state.items[page.id] = {
			...page,
			events,
		}
	},

	/**
	 * Add a new page note to the parent page.
	 *
	 * @param {Object} state
	 * @param {Object} note
	 * @return {void}
	 */
	[ADD_NOTE_TO_PAGE](state, note) {
		const page = state.items[note.call_id]
		const notes = (page.notes || []).concat(note)

		state.items[note.call_id] = {
			...page,
			notes,
		}
	},

	/**
	 * Merge the given completed call with the respective call in the store.
	 *
	 * @param {Object} state
	 * @param {Object} page
	 * @return {void}
	 */
	[COMPLETE_CALL](state, page) {
		helpers.add(state, [page])
	},

	/**
	 * Remove a model from the module by id.
	 *
	 * @param {Object} state
	 * @param {Number} id
	 * @return {void}
	 */
	[REMOVE_BY_ID](state, id) {
		helpers.removeById(state, id)
	},

	/**
	 * Delete a note from a page.
	 *
	 * @param {Object} state
	 * @param {Object} note
	 * @return {void}
	 */
	[REMOVE_NOTE_FROM_PAGE](state, note) {
		const index = state.items[note.call_id].notes.findIndex(
			item => item.id === note.id
		)

		if (index > -1) {
			state.items[note.call_id].notes.splice(index, 1)
		}
	},

	/**
	 * Remove the given status.
	 *
	 * @param {Object} state
	 * @param {String} status
	 * @return {void}
	 */
	[REMOVE_STATUS_FILTERS](state, status) {
		const index = state.filters.statuses.indexOf(status)

		state.filters.statuses.splice(index, 1)
	},

	/**
	 * Removing the current search term.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_SEARCH_FILTER](state) {
		state.filters.search = ''
	},

	/**
	 * Reset the given state back to its original value.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET](state) {
		helpers.reset(state, stateFactory())
	},

	/**
	 * Reset the given filters state back to its original state.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[RESET_FILTERS](state) {
		state.filters.ids = []

		state.filters.meta = {}
	},

	/**
	 * Set the given model's filtered providers.
	 *
	 * @param {Object} state
	 * @param {Array} providers
	 * @return {void}
	 */
	[SET_CALL_PROVIDER_FILTERS](state, providers) {
		state.filters.providers = [...[].concat(providers)]
	},

	/**
	 * Set the filter ids from the given model.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 * @return {void}
	 */
	[SET_FILTER_IDS](state, models) {
		if (models.length === 0) {
			return
		}

		models.forEach(model => {
			if (!state.filters.ids.includes(model.id)) {
				state.filters.ids.push(model.id)
			}
		})
	},

	/**
	 * Set the model filter's meta data.
	 *
	 * @param {Object} state
	 * @param {Object} meta
	 * @return {void}
	 */
	[SET_FILTER_META](state, meta) {
		state.filters.meta = {
			...meta,
		}
	},

	/**
	 * Set the incomplete pages count.
	 *
	 * @param {Object} state
	 * @param {Number} options.urgent
	 * @param {Number} options.nonUrgent
	 * @param {Number} options.total
	 * @return {void}
	 */
	[SET_INCOMPLETE_PAGING_COUNT](
		state,
		{ urgent = 0, non_urgent = 0, total = 0 }
	) {
		state.incomplete.total = total
		state.incomplete.urgent = urgent
		state.incomplete.nonUrgent = non_urgent
	},

	/**
	 * Replace the module's state with the given models.
	 *
	 * @param {Object} state
	 * @param {Array} models
	 * @return {void}
	 */
	[SET_ITEMS](state, models) {
		state.ids = []
		state.items = {}

		helpers.add(state, models)
	},

	/**
	 * Set the call's search term.
	 *
	 * @param {Object} state
	 * @param {String} term
	 * @return {void}
	 */
	[SET_SEARCH_FILTER](state, term) {
		state.filters.search = term
	},

	/**
	 * Set the given model's filtered items.
	 *
	 * @param {Object} state
	 * @param {Array} statuses
	 * @return {void}
	 */
	[SET_STATUS_FILTERS](state, statuses) {
		state.filters.statuses = [...[].concat(statuses)]
	},

	/**
	 * Set the model meta data.
	 *
	 * @param {Object} state
	 * @param {Object} meta
	 * @return {void}
	 */
	[SET_META](state, meta) {
		state.meta = {
			...meta,
		}
	},

	/**
	 * Update the given page note.
	 *
	 * @param {Object} state
	 * @param {Object} note
	 * @return {void}
	 */
	[UPDATE_PAGE_NOTE](state, note) {
		const page = state.items[note.call_id]
		const index = page.notes.findIndex(item => item.id === note.id)

		const call_note = {
			...page.notes[index],
			call_id: note.call_id,
			call_sid: page.sid,
			created_at: note.created_at,
			content: note.content,
			updated_at: note.updated_at,
			user: note.user,
		}

		if (index > -1) {
			page.notes.splice(index, 1, call_note)
		}
	},
	[SET_LOADING](state, loading) {
		state.loading = loading
	},

	/**
	 * Remove the current date range filter from the store.
	 *
	 * @param {Object} state
	 * @return {void}
	 */
	[REMOVE_DATE_RANGE_FILTER](state) {
		state.filters.starts = null
		state.filters.ends = null

		state.filters.ids = []
	},
}
